<template>
  <b-form class="p-2">
    <b-row>
      <b-col
        class="d-flex pb-2"
        cols="12"
      >
        <h3 class="text-uppercase font-weight-bold">
          datos de cobro
        </h3>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-row>
          <b-col sm="12">
            <b-form-group
              label="Cuenta bancaria"
              label-for="sale-invoice-bank-account"
            >
              <BaseSelect
                id="sale-invoice-bank-account"
                :value="model.bank_account"
                :resource="$data.$constants.RESOURCES.RESOURCE_BANK_ACCOUNTS"
                http-method="get"
                @input="update('bank_account', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Forma de pago"
              label-for="sale-invoice-type-payment"
            >
              <BaseSelect
                id="sale-invoice-type-payment"
                :resource="$data.$constants.RESOURCES.RESOURCE_TYPE_PAYMENTS"
                :value="model.type_payment"
                @input="update('type_payment', $event)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <b-row>
          <b-col sm="12">
            <b-form-checkbox
              v-model="model.received_payment_document"
              :value="1"
              :unchecked-value="0"
              class="mt-2"
              @input="update('received_payment_document', $event)"
            >
              Recepción documento de cobro
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" v-if="$route.name === 'editSaleInvoice'">
        <b-form-group label="Adjuntar Archivos">
          <FileDropZone
            ref="documentation-input"
            :files="files"
            @removed-file="removedFiles.push($event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'

export default {
  name: 'SaleInvoicesBillingForm',
  components: { 
    BaseSelect,
    FileDropZone,
  },
  mixins: [FormMixin],
  data() {
    return {
      removedFiles: [],
    }
  },
  computed: {
    files() {
      return this.model.documentation
    },
  },
  methods: {
    getDocumentationData() {
      return { documentation: this.$refs['documentation-input'].getFiles(), removedFiles: this.removedFiles }
    },
  },
}
</script>

<style scoped>
</style>
