<template>
  <b-card no-body>
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <b-row class="p-2" align-v="center" align-h="between">
        <b-col cols="auto">
          <h3 class="text-uppercase font-weight-bold mb-0">
            conceptos e importes
          </h3>
        </b-col>
        <b-col cols="auto">
          <span
            class="text-indigo border-bottom border-indigo font-weight-bold mr-1 cursor-pointer font-small-4"
            @click="createEmptySaleInvoiceLine"
          >
            + Nuevo concepto / importe
          </span>
        </b-col>
      </b-row>
      <b-table
        hover
        responsive
        :items="saleInvoiceLines"
        :fields="columns"
        :no-local-sorting="true"
      >
        <template #cell(product)="data">
          <ProductSelect
            v-model="data.item.product"
            @option-selected="handleProductSelectChange($event, data.item)"
            @clear="handleProductSelectChange($event, data.item)"
          />
        </template>
        <template #cell(description)="data">
          <b-form-input
            v-model="data.item.description"
            placeholder="Descripción"
            @change="updateSaleInvoiceLine(data.item)"
          />
        </template>
        <template #cell(amount)="data">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="data.item.amount"
              placeholder="importe"
              type="number"
              @change="updateSaleInvoiceLine(data.item)"
            />
            <b-input-group-append is-text> € </b-input-group-append>
          </b-input-group>
        </template>
        <template #cell(units)="data">
          <b-form-input
            v-model="data.item.units"
            placeholder="Unidades"
            type="number"
            @change="updateSaleInvoiceLine(data.item)"
          />
        </template>
        <template #cell(total)="data">
          {{ data.item.total | numberToLocalString }} €
        </template>
        <template #cell(actions)="data">
          <span
            class="d-inline-block px-50 text-danger"
            @click="handleDeleteIconClick(data.item.id)"
          >
            <feather-icon
              v-b-tooltip.hover
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </span>
        </template>
      </b-table>
      <b-row
        align-h="around"
        align-v="end"
        no-gutters
        class="p-1 bg-action-blue flex-column"
      >
        <b-col cols="auto" class="text-right">
          <span class="text-dark-gray font-very-small font-weight-bold"
            >TOTAL BRUTO:
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{
              (saleInvoice ? saleInvoice.total_gross : 0) | numberToLocalString
            }}
            €
          </span>
        </b-col>
        <b-col cols="auto" class="text-right">
          <span class="text-dark-gray font-very-small font-weight-bold">
            <validation-observer ref="taxes">
              <validation-provider
                v-slot="validationContext"
                name="impuesto"
                rules="required"
              >
                <small class="text-danger">
                    {{
                      validationContext.errors.length > 0
                      ? "Selecciona un impuesto para guardar"
                      : ""
                    }}
                </small>
                <br />
                TIPO IMPUESTO:
                <TaxesSelect
                  :value="saleInvoiceTax"
                  :type="saleInvoiceTaxType"
                  show-type-selector
                  @option-selected="updateSaleInvoiceTax"
                />
              </validation-provider>
            </validation-observer>
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{
              (saleInvoice ? saleInvoice.total_tax : 0) | numberToLocalString
            }}
            €
          </span>
        </b-col>
        <b-col cols="auto" class="text-right">
          <span class="text-dark-gray font-very-small font-weight-bold">
            TOTAL A FACTURAR:
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{
              (saleInvoice ? saleInvoice.total_invoice : 0)
                | numberToLocalString
            }}
            €
          </span>
        </b-col>
        <b-col cols="auto" class="text-right">
          <span class="text-dark-gray font-very-small font-weight-bold"
            >RETENCIÓN:
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{
              (saleInvoice ? saleInvoice.total_retention : 0)
                | numberToLocalString
            }}
            €
          </span>
        </b-col>
        <b-col cols="auto" class="text-right">
          <span class="text-dark-gray font-very-small font-weight-bold"
            >LÍQUIDO A PERCIBIR:
          </span>
          <span
            class="text-gray-50 justify-content-end d-inline-flex total-value-label"
          >
            {{
              (saleInvoice ? saleInvoice.total_liquid : 0) | numberToLocalString
            }}
            €
          </span>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SaleInvoicesApi from "@/api/sale-invoices-api";
import ApiRestService from "@/api/base-api";
import TaxesSelect from "@/components/tax/select/TaxesSelect.vue";
import ProductSelect from "@/components/products/select/ProductSelect.vue";

export default {
  name: "SaleInvoicesLinesTable",
  components: {
    ProductSelect,
    TaxesSelect,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      columns: [
        {
          label: "PRODUCTO",
          key: "product",
          thStyle: { "min-width": "150px" },
          sortable: false,
        },
        {
          label: "DESCRIPCIÓN",
          key: "description",
          sortable: false,
        },
        {
          label: "IMPORTE",
          key: "amount",
          sortable: false,
        },
        {
          label: "UNIDADES",
          key: "units",
          sortable: false,
          thStyle: { width: "125px" },
        },
        {
          label: "TOTAL",
          key: "total",
          sortable: false,
          thStyle: { "min-width": "125px" },
          thClass: "text-right px-2",
          tdClass: "text-right px-2",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: { width: "75px" },
        },
      ],
    };
  },
  computed: {
    ...mapGetters("saleInvoice", {
      saleInvoice: "getSaleInvoice",
      saleInvoiceLines: "getSaleInvoiceLines",
    }),
    saleInvoiceTax() {
      return this.saleInvoice?.tax;
    },
    saleInvoiceTaxType() {
      return this.saleInvoice?.tax?.type;
    },
  },
  methods: {
    ...mapActions("saleInvoice", ["loadSaleInvoice", "setSaleInvoiceTax"]),
    async fetchData() {
      try {
        await this.loadSaleInvoice(this.saleInvoice?.id);
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "Ocurrió un error al obtener el listado de lineas de factura. Por favor inténtelo de nuevo mas tarde."
        );
      }
    },
    async updateSaleInvoiceLine(saleInvoiceLine) {
      const valid = await this.$refs['taxes'].validate()
        
      if (!valid) {
        return
      }

      this.loading = true;
      try {
        await ApiRestService.edit(
          saleInvoiceLine.id,
          { ...saleInvoiceLine, sale_invoice_id: this.saleInvoice.id },
          this.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_LINES
        );
        await this.fetchData();
      } finally {
        this.loading = false;
      }
    },
    updateSaleInvoiceTax(tax) {
      this.setSaleInvoiceTax(tax);
      this.updateSaleInvoice();
    },
    async updateSaleInvoice() {
      const valid = await this.$refs['taxes'].validate()
        
      if (!valid) {
        return
      }

      this.loading = true;
      try {
        await SaleInvoicesApi.edit(this.saleInvoice?.id, this.saleInvoice);
        await this.fetchData();
      } finally {
        this.loading = false;
      }
    },
    handleProductSelectChange(product, line) {
      const saleInvoiceLine = { ...line };
      saleInvoiceLine.product_id = product?.id || "";

      this.updateSaleInvoiceLine(saleInvoiceLine);
    },
    async handleDeleteIconClick(saleInvoiceLineId) {
      const response = await this.$modal.show({
        title: "¿Está seguro?",
        text: "Se borrará el concepto o importe",
      });

      if (!response.isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await ApiRestService.delete(
          saleInvoiceLineId,
          this.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_LINES
        );
        await this.loadSaleInvoice(this.saleInvoice?.id);
      } finally {
        this.loading = false;
      }
    },
    async createEmptySaleInvoiceLine() {
      const valid = await this.$refs['taxes'].validate()
        
      if (!valid) {
        return
      }
      this.loading = true;
      try {
        await ApiRestService.create(
          { sale_invoice_id: this.saleInvoice.id },
          this.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICE_LINES
        );
        await this.loadSaleInvoice(this.saleInvoice?.id);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.total-value-label {
  width: 150px;
}
</style>
