<template>
  <div>
    <BaseCard
      title="datos de la factura"
      :footer-visible="false"
      :loading="loading"
    >
      <SaleInvoicesProfileForm
        v-model="saleInvoiceModel"
        ref="sale-invoice-profile-form"
      />
      <hr class="m-0 mx-2">
      <SaleInvoicesBillingForm
        v-model="saleInvoiceModel"
        ref="sale-invoice-billing-form"
      />
    </BaseCard>
    <SaleInvoicesLinesTable :edit-mode="true" />
    <b-row
      align-v="center"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
          :disabled="loading"
          @click="handleSaveButtonClick"
        >
          Guardar factura
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SaleInvoicesApi from '@/api/sale-invoices-api'
import SaleInvoicesLinesTable from '@/components/sale-invoices/table/SaleInvoicesLinesTable.vue'
import SaleInvoicesProfileForm from '@/components/sale-invoices/form/SaleInvoicesProfileForm.vue'
import SaleInvoicesBillingForm from '@/components/sale-invoices/form/SaleInvoicesBillingForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    SaleInvoicesBillingForm,
    SaleInvoicesProfileForm,
    SaleInvoicesLinesTable,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('saleInvoice', {
      saleInvoice: 'getSaleInvoice',
    }),
    saleInvoiceModel: {
      get() {
        return this.saleInvoice
      },
      set(newModel) {
        this.setSaleInvoice(newModel)
      },
    },
  },
  mounted() {
    this.loadSaleInvoiceDetails()
  },
  methods: {
    ...mapActions('saleInvoice', ['loadSaleInvoice', 'setSaleInvoice']),
    async loadSaleInvoiceDetails() {
      this.loading = true
      try {
        await this.loadSaleInvoice(this.$route.params.id)
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const validationSuccess = await this.$refs['sale-invoice-profile-form'].validateForm()
      if (!validationSuccess) {
        return
      }

      this.loading = true
      try {
        const { documentation, removedFiles } = this.$refs['sale-invoice-billing-form'].getDocumentationData()
        await Promise.all([
          SaleInvoicesApi.edit(this.$route.params.id, { ...this.saleInvoice, documentation }, false),
          SaleInvoicesApi.deleteFiles(this.saleInvoice.id, removedFiles),
        ])
        this.$toast('Factura actualizada con éxito.')
        await this.$router.go(-1)
      } catch (error) {
        this.handleError(error)
      }
      this.loading = false
    },
    handleError(error) {
      if (error.response?.data?.number) {
        this.$refs['sale-invoice-profile-form'].focusNumberAndSerieError()
        this.$toast.error(error.response?.data?.number[0])
      } else {
        this.$toast.error('Error al actualizar la factura. Por favor intenteló de nuevo mas tarde.')
      }
    },
  },
}
</script>

<style scoped>
</style>
