<template>
  <BaseSelect
    :id="id"
    :value="value"
    :resource="$data.$constants.RESOURCES.RESOURCE_PRODUCTS"
    :http-body="parameters"
    :disabled="disabled"
    :label="label"
    @selected="$emit('option-selected', $event)"
    @input="$emit('input', $event)"
  />
</template>

<script>
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'ProductSelect',
  components: { BaseSelect },
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'name',
    },
    categoryGroupId: {
      type: Number,
      default: null,
    },
    saleProductsVisible: {
      type: Boolean,
      default: null,
    },
    purchaseProductsVisible: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    parameters() {
      const parameters = {}
      parameters.category_group_id = this.categoryGroupId

      if (this.saleProductsVisible !== null) {
        parameters.sale_product = this.saleProductsVisible ? 1 : 0
      }

      if (this.purchaseProductsVisible !== null) {
        parameters.purchase_product = this.purchaseProductsVisible ? 1 : 0
      }

      return parameters
    },
  },
}
</script>

<style scoped>
</style>
